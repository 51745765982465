.appLayout {
    max-height: 100vh;
}

.container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100vh;
    justify-content: center;
}

.logoContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.logo {
    height: 100px;
}

.footer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.socialContainer {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    min-width: 200px;
}

.icon {
    font-size: 35px;
}